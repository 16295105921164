<template>
  <el-dialog :title="downUrlType == 1 ? '导出藏品数据筛选' : '导出档案数据筛选'" width="50%"
             :close-on-click-modal="false"
             append-to-body :visible.sync="visible"
             v-if="visible">
    <el-transfer filterable
                 ref="transfer"
                 v-model="selId"
                 :titles="['选择数据', '已选数据']"
                 target-order="push"
                 :props="{key: 'archivesBasicId',label: 'basicName'}"
                 :data="transferData">
                      <span slot-scope="{ option }"
                            draggable="!option.disabled">
                        {{ option.basicName }}
                      </span>
    </el-transfer>
    <span slot="footer" class="dialog-footer">
            <el-button size="small" @click="visible = false">取消</el-button>
            <el-button size="small" type="primary" @click="doSubmit()" v-noMoreClick>导出</el-button>
        </span>
  </el-dialog>
</template>

<script>
import Sortable from 'sortablejs'

export default {
  data() {
    return {
      visible: false,
      archivesBasicDataId: '',
      archivesBasicDataName: '',
      selId: [],
      selVal: [],
      transferData: [],
      idList: [],
      downUrlType: '',
      fetchUrl: '',
    }
  },
  methods: {
    // idList(列表选择的数据id集合), data(元数据所有字段), id(档案分类id), name(档案分类名称),type(没有则默认档案接口，1为藏品登记导出接口)
    init(idList, data, id, name, type, url) {
      this.selId = []
      this.selVal = []
      this.idList = idList
      this.archivesBasicDataId = id
      this.archivesBasicDataName = name
      this.transferData = data
      this.downUrlType = type
      this.fetchUrl = url
      this.visible = true
      this.$nextTick(() => {
        this.initSortable()
      })
    },
    // 初始化拖拽排序
    initSortable() {
      const transfer = this.$refs.transfer.$el
      const rightPanel = transfer.getElementsByClassName('el-transfer-panel')[1].getElementsByClassName('el-transfer-panel__body')[0]
      const rightEl = rightPanel.getElementsByClassName('el-transfer-panel__list')[0]
      Sortable.create(rightEl, {
        onEnd: evt => {
          const {oldIndex, newIndex} = evt
          const temp = this.selId[oldIndex]
          if (!temp || temp === 'undefined') {
            return
          } // 解决右边最后一项从右边拖左边，有undefined的问题
          this.$set(this.selId, oldIndex, this.selId[newIndex])
          this.$set(this.selId, newIndex, temp)
        }
      })
    },
    doSubmit() {
      if (!this.selId.length) {
        this.$message.warning('请选择需要导出的数据！')
        return
      }
      this.selVal = []
      this.selId.map((id, index) => {
        this.transferData.map(item => {
          if (id == item.archivesBasicId) {
            this.selVal.push({
              chineseName: item.basicName,
              filedName: item.code,
              sort: index + 1,
              archivesBasicTypeCode: item.archivesBasicTypeCode,
              dataFormat: item.dataFormat,
            })
          }
        })
      })
      console.log(this.selVal)
      this.$nextTick(() => {
        let url = this.api.record.export
        let data = {
          archivesBasicDataId: this.archivesBasicDataId,
          filedSortDTOList: this.selVal,
          idList: this.idList,
          type: 0
        }
        if (this.downUrlType == 1) {
          url = this.api.collection.export
          data = {
            filedSortDTOList: this.selVal,
            list: this.idList,
            type: 0
          }
        }
        if (this.fetchUrl) {
          url = this.fetchUrl
        }
        this.exportExcel(url, data, this.archivesBasicDataName, 'post')
        this.$nextTick(() => {
            this.$emit('downLoad')
            this.visible = false
        })
      })
    }
  }
}
</script>

<style scoped>
.el-transfer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.el-transfer >>> .el-transfer-panel {
  width: 32% !important;
}
</style>
